<template>
  <span class="inline-flex align-middle" :class="containerClass" @click.stop="toggle" @keyup.enter="toggle" @keydown.space.prevent @keyup.space="toggle" @keyup.down.right="focusNext" @keyup.up.left="focusPrevious">
    <span
      :id=id
      class="relative inline-flex justify-center font-bold items-center text-blue-dark group-hover:text-white bg-white rounded text-center leading-none"
      :class="elementClass"
      :tabindex="tabindex"
      role="group"
      aria-label=""
      :data-test=id
    >
      <span :class="boxClass" class="absolute inset-0 rounded"></span>
      <span :class="iconClass" class="absolute fill-current transform transition-transform ease-out duration-300 rs_skip" aria-label="Selecteren" v-html="icon"></span>
    </span><span v-if="$slots.default" :class="labelClass"><slot></slot></span>
  </span>
</template>

<script>

const minusIcon = require('@Icons/minus.svg')

const elementClasses = {
  5: 'w-5 h-5 min-w-5 min-h-5',
  6: 'w-6 h-6 min-w-6 min-h-6'
};

const iconSizes = {
  5: 'w-2.5 h-2.5',
  6: 'w-3 h-3'
};

const checkedSizes = {
  5: 'inset-2',
  6: 'inset-2'
}

const labelPaddings = {
  2: 'pl-2',
  4: 'pl-4'
}

export default {
  props: {
    id: String,
    value: Boolean,
    partiallyChecked: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 6
    },
    centerLabel: {
      type: Boolean,
      default: true
    },
    labelPadding: {
      type: Number,
      default: 4
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    icon() {
      if (this.partiallyChecked) {
        return minusIcon
      }
      return ''
    },
    containerClass() {
      return {
        'cursor-pointer group': !this.disabled,
        'items-center': this.centerLabel
      }
    },
    labelClass() {
      return labelPaddings[this.labelPadding]
    },
    elementClass() {
      return elementClasses[this.size]
    },
    boxClass() {
      if (this.value || this.partiallyChecked) {
        return 'bg-blue'
      }
      return 'bg-white border-2 border-gray-500 group-hover:border-blue group-focus:border-blue'
    },
    iconClass() {
      if (this.value) {
        return checkedSizes[this.size] + ' bg-white rounded-sm'
      }
      if (this.partiallyChecked) {
        return iconSizes[this.size] + ' text-white scale-100'
      }
      return iconSizes[this.size] + ' text-blue scale-0'
    }
  },
  methods: {
    toggle () {
      if (this.disabled) {
        return
      }
      this.$emit('input', !this.value)
    },
    focusNext() {
      this.$emit('focus-next', this)
    },
    focusPrevious() {
      this.$emit('focus-previous', this)
    }
  }
}
</script>
