var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "absolute inset-0 z-10",
      class: { "backdrop-filter backdrop-grayscale": _vm.backdrop },
    },
    [
      _c("div", {
        staticClass:
          "absolute top-0 left-0 right-0 h-1 bg-blue animate-loading",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }