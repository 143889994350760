var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "inline-flex align-middle",
      class: _vm.containerClass,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggle.apply(null, arguments)
        },
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.toggle.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            )
              return null
            return _vm.toggle.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ]) &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight",
              ])
            )
              return null
            if ("button" in $event && $event.button !== 2) return null
            return _vm.focusNext.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"]) &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            )
              return null
            if ("button" in $event && $event.button !== 0) return null
            return _vm.focusPrevious.apply(null, arguments)
          },
        ],
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          )
            return null
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass:
            "relative inline-flex justify-center font-bold items-center text-blue-dark group-hover:text-white bg-white rounded text-center leading-none",
          class: _vm.elementClass,
          attrs: {
            id: _vm.id,
            tabindex: _vm.tabindex,
            role: "group",
            "aria-label": "",
            "data-test": _vm.id,
          },
        },
        [
          _c("span", {
            staticClass: "absolute inset-0 rounded",
            class: _vm.boxClass,
          }),
          _vm._v(" "),
          _c("span", {
            staticClass:
              "absolute fill-current transform transition-transform ease-out duration-300 rs_skip",
            class: _vm.iconClass,
            attrs: { "aria-label": "Selecteren" },
            domProps: { innerHTML: _vm._s(_vm.icon) },
          }),
        ]
      ),
      _vm.$slots.default
        ? _c("span", { class: _vm.labelClass }, [_vm._t("default")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }