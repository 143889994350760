<template>
  <div class="absolute inset-0 z-10" :class="{'backdrop-filter backdrop-grayscale': backdrop}">
    <div class="absolute top-0 left-0 right-0 h-1 bg-blue animate-loading"></div>
  </div>
</template>

<script>
export default {
  props: {
    backdrop: {
      type: Boolean,
      default: true
    }
  }
}
</script>
